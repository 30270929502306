import React from "react"
import { DiAndroid, DiApple } from "react-icons/di"
import { FaRegHandPointRight } from "react-icons/fa"
import {GiGamepad} from "react-icons/gi"
import { StaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import mamakplayGIF from '../images/mamakplay-download-banner.gif'

const getData = graphql`
  {
    info: site {
      siteMetadata {
        android
        ios
        pcgame
        mamakplay
      }
    }
  }
`

export default function download() {
  return (
    <StaticQuery
      query={getData}
      render={data => {
        const siteData = data.info.siteMetadata
        const { android, ios, pcgame, mamakplay } = siteData
        return (
          <div className="container">
            <div className="row">
              <div className="col-lg-3" />
              <div className="col-sm-12 col-lg-6 pl-0 py-2 mr-2">
                <OutboundLink
                  href={mamakplay}
                  alt="Mamakplay Casino Site"
                  className="text-white"
                >
                  <img src={mamakplayGIF} alt="Mamakplay Online Casino Site" className="rounded" />
                </OutboundLink>
              </div>
            </div>
            <div className="row pt-5 pb-1">
              <div className="col-lg-3" />
              <div className="col-sm-12 col-lg-6 appbox py-2 mr-2 mb-3">
                <div className="d-flex pb-2">
                  <div>
                    <FaRegHandPointRight className="icon-test pr-1" />
                  </div>
                  <div>XE88 Free Play Account</div>
                </div>
                <div className="text-red text-center">
                Free Play Acc demo1000~demo2000 password:1234
                </div>
              </div>
            </div>
            <div className="row pt-2 pb-1">
              <div className="col-lg-3" />
              <div className="col-sm-12 col-lg-6 appbox py-2 mr-2 mb-3">
                <div className="d-flex pb-2">
                  <div>
                    <DiAndroid className="icon-android pr-1" />
                  </div>
                  <div>APP for Android</div>
                </div>
                <OutboundLink
                  href={android}
                  alt="XE88 Android APK Download"
                  className="text-white"
                >
                  <button className="appbox-button py-2 my-2">Android</button>
                </OutboundLink>
              </div>
            </div>
            <div className="row pt-2 pb-1">
              <div className="col-lg-3" />
              <div className="col-sm-12 col-lg-6 appbox py-2 mr-2 mb-3">
                <div className="d-flex pb-2">
                  <div>
                    <DiApple className="icon-ios-64 pr-1" />
                  </div>
                  <div>APP for IOS</div>
                </div>
                <OutboundLink
                  href={ios}
                  alt="XE88 Iphone Download"
                  className="text-white"
                >
                  <button className="appbox-button py-2 my-2 bg-dark-red">
                    IOS
                  </button>
                </OutboundLink>
              </div>
            </div>
            <div className="row pt-2 pb-1">
              <div className="col-lg-3" />
              <div className="col-sm-12 col-lg-6 appbox py-2 mr-2 mb-3">
                <div className="d-flex pb-2">
                  <div>
                    <GiGamepad className="icon-ios-32 pr-1" />
                  </div>
                  <div>PC Game</div>
                </div>
                <OutboundLink
                  href={pcgame}
                  alt="XE88 PC Game Download"
                  className="text-white"
                >
                  <button className="appbox-button py-2 my-2 bg-dark-orange">
                    PC Game
                  </button>
                </OutboundLink>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}
